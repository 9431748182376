import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import '@/utils/lib-flexible'
import './auto-update'
import 'element-ui/lib/theme-chalk/index.css'   //样式表
import '@/css/all.css'   //样式表
import enLang from 'element-ui/lib/locale/lang/zh-CN'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
// register globally
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n=new VueI18n({
  locale:localStorage.getItem('languageSet')||'en',  //从localStorage里获取用户中英文选择，没有则默认中文
  messages:{
    'zh':require('./components/language/zh'),
    'en':require('./components/language/en')
  }
})
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import '@/svgIcon/index.js'
import setStorage from '@/utils/Storge'/* 封装缓存 */
Vue.prototype.$Storage=setStorage  
/* import 'lib-flexible'//适配依赖 */
import '@/utils/lib-flexible'

// import '@/css/all.css'   //全局样式表
import md5 from "js-md5" //md5加密
Vue.prototype.$md5=md5

Vue.use(Element, {
  size: 'medium', // set element-ui default size
  locale: {el:{...enLang.el,pagination:{
    pagesize:'rows/page',
    total: `Totally {total} rows`,

        goto: 'goto',

        pageClassifier: 'page'
  }}} // 如果使用中文，无需设置，请删除
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
