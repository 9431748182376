
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout 竖向*/
import Layout from '@/views/layout/Layout'
/* abeam 横向*/
import Abeam from '@/views/abeam/Layout'


export const routerMap = {
  Layout: () => Layout,

}
export const constantRouterMap = [
  //首次加载时先走登录页
  {
    path: '/login',
    name: 'login',
    component: () => {
      return import("@/views/login/index")
    }
  },

  //路由重定向
  {
    path: '/',
    redirect: "/login"
  },
  // 没有下拉菜单，页面卸载children中
  {
    path: '/homeView',
    component: Abeam,
    redirect: 'home',
    name: 'homeView',
    alwaysShow: true,
    meta: {
      title: 'Home',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/home',
        component: () => import('@/views/pages/home'),
        name: 'home',
        meta: { title: 'Home',title1: '首页', }
      },
    ]
  },
  {
    path: '/JbrowseView',
    component: Layout,
    redirect: 'Jbrowse',
    name: 'JbrowseView',
    alwaysShow: true,
    meta: {
      title: 'Jbrowse',//标题
      title1: '基因浏览器',
      icon: 'browse'//图标
    },
    children: [
      {
        path: '/Jbrowse',
        component: () => import('@/views/pages/Jbrowse'),
        name: 'Jbrowse',
        meta: { title: 'Jbrowse', title1: '基因浏览器',}
      },
    ]
  },
  {
    path: '/PhenotypeView',
    component: Layout,
    redirect: 'Phenotype',
    name: 'PhenotypeView',
    alwaysShow: true,
    meta: {
      title: 'Phenotype',//标题
      title1: '表型',//标题
      icon: 'analysis'//图标
    },
    children: [
      {
        path: '/Phenotype',
        component: () => import('@/views/pages/Phenotype'),
        name: 'Phenotype',
        meta: { title: 'Phenotype', title1: '表型'}
      },
    ]
  },
  {
    path: '/PopulationView',
    component: Layout,
    redirect: 'Population',
    name: 'PopulationView',
    alwaysShow: true,
    meta: {
      title: 'Population',//标题
      title1: '种群',//标题
      icon: 'Population'//图标
    },
    children: [
      {
        path: '/Structure',
        component: () => import('@/views/pages/Structure'),
        name: 'Structure',
        meta: { title: 'Structure',title1: '体系', icon: 'structure'}
      },
      {
        path: '/Signals-pi',
        component: () => import('@/views/pages/Signals-pi'),
        name: 'Signals-π',
        meta: { title: 'Signals-π',title1: '肽信号', icon: 'pi'}
      },
      {
        path: '/Signals-fst',
        component: () => import('@/views/pages/Signals-fst'),
        name: 'Signals-fst',
        meta: { title: 'Signals-fst',title1: '分化指数信号', icon: 'fst'}
      },
    ]
  },
  {
    path: '/VariationView',
    component: Layout,
    redirect: 'Variation',
    name: 'VariationView',
    alwaysShow: true,
    meta: {
      title: 'Variation',//标题
      title1: '变异',//标题
      icon: 'Variation'//图标
    },
    children: [
      {
        path: '/SNP-Seek',
        component: () => import('@/views/pages/SNP-Seek'),
        name: 'SNP-Seek',
        meta: { title: 'SNP-Seek', title1: '恒久性变异',icon: 'search1'}
      },
      {
        path: '/Ex-Seq',
        component: () => import('@/views/pages/Ex-Seq'),
        name: 'Ex-Seq',
        meta: { title: 'Ex-Seq', title1: '序列提取',icon: 'gwas1'}
      },
      {
        path: '/QTL',
        component: () => import('@/views/pages/QTL'),
        name: 'QTL',
        meta: { title: 'QTL', title1: '染色体',icon: 'dna'}
      },
      {
        path: '/GWAS',
        component: () => import('@/views/pages/GWAS'),
        name: 'GWAS',
        meta: { title: 'GWAS', title1: '全基因组关联研究',icon: 'gwas1'}
      },
    ]
  },
  /* {
    path: '/BlastView',
    component: Layout,
    redirect: 'Blast',
    name: 'BlastView',
    alwaysShow: true,
    meta: {
      title: 'Blast',//标题
      icon: 'blast'//图标
    },
    children: [
      {
        path: '/Blast',
        component: () => import('@/views/pages/Blast'),
        name: 'Blast',
        meta: { title: 'Blast', }
      },
    ]
  }, */
/*   {
    path: '/sequenceView',
    component: Layout,
    redirect: 'sequence',
    name: 'sequenceView',
    alwaysShow: true,
    meta: {
      title: 'Sequence',//标题
      icon: 'sequence'//图标
    },
    children: [
      {
        path: '/sequence',
        component: () => import('@/views/pages/sequence'),
        name: 'sequence',
        meta: { title: 'Sequence', }
      },
    ]
  }, */
  // {
  //   path: '/SNP-SeekView',
  //   component: Layout,
  //   redirect: 'SNP-Seek',
  //   name: 'SNP-SeekView',
  //   alwaysShow: true,
  //   meta: {
  //     title: 'SNP-Seek',//标题
  //     icon: 'search'//图标
  //   },
  //   children: [
  //     {
  //       path: '/SNP-Seek',
  //       component: () => import('@/views/pages/SNP-Seek'),
  //       name: 'SNP-Seek',
  //       meta: { title: 'SNP-Seek', }
  //     },
  //   ]
  // },
  // {
  //   path: '/GWASView',
  //   component: Layout,
  //   redirect: 'GWAS',
  //   name: 'GWASView',
  //   alwaysShow: true,
  //   meta: {
  //     title: 'GWAS',//标题
  //     icon: 'gwas'//图标
  //   },
  //   children: [
  //     {
  //       path: '/GWAS',
  //       component: () => import('@/views/pages/GWAS'),
  //       name: 'GWAS',
  //       meta: { title: 'GWAS', }
  //     },
  //   ]
  // },
  {
    path: '/toolsView',
    component: Layout,
    redirect: 'tools',
    name: 'toolsView',
    alwaysShow: true,
    meta: {
      title: 'Tools',//标题
      title1: '工具',//标题
      icon: 'tools'//图标
    },
    children: [
      {
        path: '/Blast',
        component: () => import('@/views/pages/Blast'),
        name: 'Blast',
        meta: { title: 'Blast',  title1: '序列比对',icon:'blast'}
      },
      {
        path: '/sequence',
        component: () => import('@/views/pages/sequence'),
        name: 'sequence',
        meta: { title: 'Sequence Fetch',title1: '系列提取', icon:'sequence'}
      },
      {
        path: '/MSA',
        component: () => import('@/views/pages/MSA'),
        name: 'MSA',
        meta: { title: 'MSA', title1: '多序列比对', icon:'msa'}
      },
      {
        path: '/KEGG',
        component: () => import('@/views/pages/KEGG'),
        name: 'KEGG',
        meta: { title: 'KEGG Enrichment',title1: 'KEGG富集',  icon:'kegg'}
      },
      {
        path: '/GO',
        component: () => import('@/views/pages/GO'),
        name: 'GO',
        meta: { title: 'GO Enrichment', title1: 'GO富集', icon:'go'}
      },
      /* {
        path: '/sequence',
        component: () => import('@/views/pages/sequence'),
        name: 'sequence',
        meta: { title: 'Sequence', }
      }, */
      /* {
        path: '/SNP-Seek',
        component: () => import('@/views/pages/SNP-Seek'),
        name: 'SNP-Seek',
        meta: { title: 'SNP-Seek', }
      },
      {
        path: '/Ex-Seq',
        component: () => import('@/views/pages/Ex-Seq'),
        name: 'Ex-Seq',
        meta: { title: 'Ex-Seq', }
      }, */
      /* {
        path: '/Phenotype',
        component: () => import('@/views/pages/Phenotype'),
        name: 'Phenotype',
        meta: { title: 'Phenotype', }
      }, */
      /* {
        path: '/QTL',
        component: () => import('@/views/pages/QTL'),
        name: 'QTL',
        meta: { title: 'QTL', }
      },
      {
        path: '/GWAS',
        component: () => import('@/views/pages/GWAS'),
        name: 'GWAS',
        meta: { title: 'GWAS', }
      }, */
    ]
  },
  
  {
    path: '/downloadView',
    component: Layout,
    redirect: 'download',
    name: 'downloadView',
    alwaysShow: true,
    meta: {
      title: 'Download',//标题
      title1: '下载',
      icon: 'download'//图标
    },
    children: [
      {
        path: '/download',
        component: () => import('@/views/pages/download'),
        name: 'download',
        meta: { title: 'Download', title1: '下载',}
      },
    ]
  },
  // 有下拉菜单，写在children中
 /*  {
    path: '/home1',
    component: Layout,
    // redirect: 'area',
    name: 'anti_channel',
    alwaysShow: true,
    meta: {
      title: '其他页面',//标题
      icon: 'home'//图标
    },
    children: [
      {
        path: '/home1/mm',
        component: () => import('@/views/login/index'),
        name: 'mm',
        meta: { title: '测试2', }
      },
      {
        path: '/home1/ceshi',
        component: () => import('@/views/login/index'),
        name: 'ceshi',
        meta: { title: '测试3', }
      },
    ]
  } */
 
  // {
  //	 path: '/404',
  //	 component: () => import('@/views/errorPage/404'),
  //	 hidden: true
  // },
  // {
  //	 path: '/401',
  //	 component: () => import('@/views/errorPage/401'),
  //	 hidden: true
  // }
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
]

