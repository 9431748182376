import request from '@/utils/request'
// import md5 from "js-md5"
// home页面描述信息及数据库统计结果
export function basicInfo(url) {
    return request({
        url: url+'/mongo/basicInfo/',
        method: 'get',
        // params:data,//get请求参数
    })
}
//轮播图
export function banner(url) {
    return request({
        // url: url+'/mongo/basicInfo/',
        url: url+'/mongo/bg_img/',
        method: 'get',
        // params:data,//get请求参数
    })
}
// download数据下载
export function download(url) {
    return request({
        url: url+'/mongo/download/',
        method: 'get',
        // params:data,//get请求参数
    })
}
// blast 任务接口参数
export function blastParams(url) {
    return request({
        url: url+'/mongo/blast_params/',
        method: 'get',
        // params:data,//get请求参数
    })
}
/* //SNP-Seek
export function SNPseek() {
    return request({
        url: '/mongo/snp/',
        method: 'get',
        // params:data,//get请求参数
    })
} */
//jbrowse
export function jbrowse(url) {
    return request({
        url: url+'/mongo/jbrowse/',
        method: 'get',
        // params:data,//get请求参数
    })
}
//search
/* export function searchFun(url,param) {
    return request({
        url: url,
        method: 'get',
        params: param,
    })
} */
export function searchFun(url,data) {
    return request({
        url: url+'/mongo/function',
        method: 'get',
        params:data,//get请求参数
    })
}
//GWAS下拉框
export function trait(url,data) {
    return request({
        url: url+'/mongo/trait',
        method: 'get',
        params:data,//get请求参数
    })
}
//seq例子
export function seqEg(url,data) {
    return request({
        url: url+'/mongo/seq_fetch_eg',
        method: 'get',
        params:data,//get请求参数
    })
}
//SNP例子
export function snpEg(url,data) {
    return request({
        url: url+'/mongo/snp_eg',
        method: 'get',
        params:data,//get请求参数
    })
}
export function getSearch(url,param) {
    return request({
        url: url,
        method: 'get',
        params: param,
    })
}
//SNP
export function SNPseek(url,param) {
    return request({
        url: url,
        method: 'get',
        params: param,
    })
}

export function getFasta(url,data) {
    return request({
        url: url,
        method: 'post',
        data,
    })
}

