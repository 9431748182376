<template>
	<div class="mainTopNavItemBox">
		<hamburger  :toggle-click="toggleSideBar" :is-active="isCollapse"  class="toggleSideBarBox"/>
		<breadcrumb class="currentLocationBox"/>
    
		<!-- <div class="rightMenuBox">
			<el-dropdown>
        <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
				<el-dropdown-menu class="" slot="dropdown">
					<el-dropdown-item  @click.native="changePad">修改密码</el-dropdown-item>
					<el-dropdown-item  @click.native="logout">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div> -->
	</div>
</template>

<script>
import Hamburger from '@/components/Hamburger'
import Breadcrumb from '@/components/Breadcrumb'
export default {
  name: 'navbar',
   props:{
   //传值
   isCollapse:{
    type:Boolean,
    default:true
   }
   },
  data() {
    return {
      name:this.$Storage.getItem("adm_name")
    };
  },
  components:{
    //引入模块
    Hamburger,
    Breadcrumb
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function() {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    // console.log('Home');
    
  },
  methods: {
    // 组件的方法
    toggleSideBar(){
        this.$emit("isCollapseFunction",!this.isCollapse)
    },
    changePad(){

    },
    /* 退出 */
    logout(){
      this.$Storage.removeItem("adm_name")
      this.$Storage.removeItem("Admin-Token")
      this.$router.push({name:"login"})
    }
  },
  beforeCreate: function() {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function() {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function() {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function() {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function() {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function() {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function() {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  }
};

</script>
<style scoped>
.mainTopNavItemBox{width:1440px;height:70px;line-height:70px;border-radius:0px!important;}
.mainTopNavItemBox img{
  float: left;
    margin-left: 3%;
    width: 122px;
    height: 38px;
    /* width: 7%; */
    /* margin-top: 6px; */
    /* padding: 7px; */
    padding-top: 16px;
    padding-bottom: 16px;
}
.mainTopNavItemBox .toggleSideBarBox{line-height:58px;height:50px;float:left;padding:0 10px;}
.mainTopNavItemBox .currentLocationBox{float:left;line-height:58px;height:50px;padding:0 10px;}
.mainTopNavItemBox .rightMenuBox{float:right;height:100%;position:relative;padding-right:20px;}
.mainDropdownMenuBox{width:auto!important;}
.el-avatar{
  margin-top: 16px;
}
.el-dropdown-menu{
  margin-top: -10px !important;
}
</style>